<template>
  <div>
    <div
      class="stl_ stl_02"
      style="
        font-family: 'Times New Roman';
        font-size: medium;
        white-space: normal;
      "
    >
      <div class="stl_view">
        <div class="stl_05 stl_06">
          <div class="stl_01" style="left: 7.5em; top: 6.1095em">
            <span class="stl_07 stl_08 stl_09" style="font-weight: bold"
              >引言 &nbsp;</span
            >
          </div>
          <div class="stl_01" style="left: 7.5em; top: 8.2895em">
            <span class="stl_07 stl_08 stl_10">欢迎你使用我们的产品和服务</span
            ><span class="stl_07 stl_08 stl_11">！</span
            ><span class="stl_07 stl_08 stl_09">我</span
            ><span class="stl_07 stl_08 stl_12">们深知个人信息对你的重要性</span
            ><span class="stl_07 stl_08 stl_13">，</span
            ><span class="stl_07 stl_08 stl_09">你</span
            ><span class="stl_07 stl_08 stl_14">的信任对我们非常 &nbsp;</span>
          </div>
          <div class="stl_01" style="left: 7.5em; top: 9.5895em; z-index: 47">
            <span class="stl_07 stl_08 stl_15">重要</span
            ><span class="stl_07 stl_08 stl_16">，</span
            ><span class="stl_07 stl_08 stl_09">我</span
            ><span class="stl_07 stl_08 stl_17"
              >们将根据法律法规要求并参照行业最佳实践为你的个人信息安全提供充分保障。
              &nbsp;</span
            >
          </div>
          <div class="stl_01" style="left: 7.5em; top: 10.8895em">
            <span class="stl_07 stl_08 stl_18"
              >鉴于此，<span
                style="
                  font-family: 'Times New Roman';
                  font-size: medium;
                  font-weight: 700;
                  white-space: normal;
                "
                >汉鼎科技</span
              ></span
            ><span class="stl_19 stl_08 stl_20" style="font-weight: bold"
              >平台</span
            ><span class="stl_19 stl_08 stl_21">（以下简称“我公司”/“</span
            ><span class="stl_19 stl_08 stl_09" style="font-weight: bold"
              >我们</span
            ><span class="stl_19 stl_08 stl_22">”）</span
            ><span class="stl_07 stl_08 stl_23">制定本《隐私</span
            ><span class="stl_07 stl_08 stl_20"
              >声明》（下称“本声明”）以帮助你充分了解在你使用我们的产品与/或服务时，我们</span
            ><span class="stl_07 stl_08 stl_24"
              >如何收集、使用、保存、共享和转让这些信息，以及我们为你提供的访问、更新、删除&nbsp;&nbsp;</span
            >和保护这些信息的方式。&nbsp;&nbsp;
          </div>
          <div class="stl_01" style="left: 7.5em; top: 16.9595em">
            <span class="stl_07 stl_08 stl_25" style="font-weight: bold"
              >《隐私声明》 &nbsp;</span
            >
          </div>
          <div class="stl_01" style="left: 7.5em; top: 19.1395em">
            <span class="stl_07 stl_08 stl_26" style="word-spacing: 0.0043em"
              >发布日期：2023年 6 月 1 日 &nbsp;</span
            >
          </div>
          <div class="stl_01" style="left: 7.5em; top: 21.3095em">
            <span class="stl_07 stl_08 stl_26" style="word-spacing: 0.0043em"
              >生效日期：2023 年 6 月 1 日 &nbsp;</span
            >
          </div>
          <div class="stl_01" style="left: 7.5em; top: 25.6595em">
            <span class="stl_07 stl_08 stl_21" style="font-weight: bold"
              >本隐私声明将帮助你了解以下内容： &nbsp;</span
            >
          </div>
          <div class="stl_01" style="left: 7.5em; top: 27.8395em">
            <span class="stl_07 stl_08 stl_26"
              >一、我们如何收集和使用你的个人信息 &nbsp;</span
            >
          </div>
          <div class="stl_01" style="left: 7.5em; top: 30.0095em">
            <span class="stl_07 stl_08 stl_17"
              >二、我们如何“共享”“转让”“公开披露”你的个人信息 &nbsp;</span
            >
          </div>
          <div class="stl_01" style="left: 7.5em; top: 32.1895em">
            <span class="stl_07 stl_08 stl_26"
              >三、我们如何保护和保存你的个人信息 &nbsp;</span
            >
          </div>
          <div class="stl_01" style="left: 7.5em; top: 34.3595em">
            <span class="stl_07 stl_08 stl_20"
              >四、未成年人的个人信息保护 &nbsp;</span
            >
          </div>
          <div class="stl_01" style="left: 7.5em; top: 36.5395em">
            <span class="stl_07 stl_08 stl_14">五、如何联系我们 &nbsp;</span>
          </div>
          <div class="stl_01" style="left: 7.5em; top: 38.7095em">
            <span class="stl_07 stl_08 stl_27">六、通知和修订 &nbsp;</span>
          </div>
          <div class="stl_01" style="left: 9.25em; top: 40.8895em">
            <span class="stl_07 stl_08 stl_28"
              >本声明适用于“<span
                style="
                  font-family: 'Times New Roman';
                  font-size: medium;
                  font-weight: 700;
                  white-space: normal;
                "
                >汉鼎科技</span
              >”（以下简称“<span
                style="
                  font-family: 'Times New Roman';
                  font-size: medium;
                  font-weight: 700;
                  white-space: normal;
                "
                >汉鼎科技</span
              >平台”）所</span
            ><span class="stl_07 stl_08 stl_10">提供的所有产品和服务</span
            ><span class="stl_07 stl_08 stl_11">。</span
            ><span class="stl_19 stl_08 stl_29"
              >但不适用于第三方向您提供的其他服务</span
            ><span class="stl_19 stl_08 stl_13">，</span
            ><span class="stl_19 stl_08 stl_09">其</span
            ><span class="stl_19 stl_08 stl_14">他服务适用其向您</span
            >另行说明的个人信息收集使用规则。<span class="stl_07 stl_08 stl_20"
              >在使用我们所提供的产品或服务前</span
            ><span class="stl_07 stl_08 stl_11">，</span
            ><span class="stl_07 stl_08 stl_09">请</span
            ><span class="stl_07 stl_08 stl_29"
              >你务必仔细阅读和理解对应的隐私声明</span
            ><span class="stl_07 stl_08 stl_30">，</span
            ><span class="stl_07 stl_08 stl_09">特</span
            ><span class="stl_07 stl_08 stl_31">别是</span
            ><span class="stl_07 stl_08 stl_32" style="font-weight: bold"
              >以粗体/粗体下划线</span
            ><span class="stl_07 stl_08 stl_33"
              >标识的条款，你应重点阅读，确认你充分理解并同意后再开始使用。&nbsp;&nbsp;</span
            >
          </div>
          <div class="stl_01" style="left: 7.5em; top: 48.5695em">
            <span class="stl_07 stl_08 stl_29"
              >如对本声明内容有任何疑问、意见或建议，你可与我们联系。
              &nbsp;</span
            >
          </div>
          <div class="stl_01" style="left: 7.5em; top: 50.7494em">
            <span
              class="stl_07 stl_08 stl_20"
              style="font-weight: bold; word-spacing: 0.2577em"
              >第二部分 隐私声明 &nbsp;</span
            >
          </div>
          <div class="stl_01" style="left: 7.5em; top: 52.9195em">
            <span class="stl_07 stl_08 stl_18" style="font-weight: bold"
              >一、我们如何收集和使用你的个人信息 &nbsp;</span
            >
          </div>
          <div class="stl_01" style="left: 7.5em; top: 55.0994em">
            <span class="stl_07 stl_08 stl_18" style="font-weight: bold"
              >个人信息</span
            ><span class="stl_07 stl_08 stl_29"
              >是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然</span
            ><span class="stl_07 stl_08 stl_20"
              >人身份或者反映特定自然人活动情况的各种信息</span
            ><span class="stl_07 stl_08 stl_11">。</span
            ><span class="stl_07 stl_08 stl_20" style="font-weight: bold"
              >个人敏感信息</span
            ><span class="stl_07 stl_08 stl_20">是指一旦泄露</span
            ><span class="stl_07 stl_08 stl_30">、</span
            ><span class="stl_07 stl_08 stl_09">非</span
            ><span class="stl_07 stl_08 stl_31">法提</span
            ><span class="stl_07 stl_08 stl_20"
              >供或滥用可能危害人身和财产安全</span
            ><span class="stl_07 stl_08 stl_11">，</span
            ><span class="stl_07 stl_08 stl_09">极</span
            ><span class="stl_07 stl_08 stl_34">易导致个人名誉</span
            ><span class="stl_07 stl_08 stl_11">、</span
            ><span class="stl_07 stl_08 stl_09">身</span
            ><span class="stl_07 stl_08 stl_35">心健康受到损害或歧视性待</span
            ><span class="stl_07 stl_08 stl_36"
              >遇的个人信息。本隐私声明中涉及的个人信息包括：基本信息（包括</span
            ><span class="stl_07 stl_08 stl_09" style="font-weight: bold"
              >个人姓名</span
            ><span class="stl_07 stl_08 stl_37">、</span
            ><span class="stl_07 stl_08 stl_09" style="font-weight: bold"
              >个人电</span
            ><span class="stl_07 stl_08 stl_20" style="font-weight: bold"
              >话号码</span
            ><span class="stl_07 stl_08 stl_38">）；网络身份标识信息（包括</span
            ><span class="stl_07 stl_08 stl_18" style="font-weight: bold"
              >个人微信账号名字</span
            ><span class="stl_07 stl_08 stl_39">）；个人财产信息</span
            ><span class="stl_07 stl_08 stl_25" style="font-weight: bold"
              >（包括交易和</span
            ><span class="stl_07 stl_08 stl_25" style="font-weight: bold"
              >消费记录）</span
            ><span class="stl_07 stl_08 stl_31">；</span
            ><span class="stl_07 stl_08 stl_40" style="font-weight: bold"
              >个人位置信息</span
            ><span class="stl_07 stl_08 stl_40">。&nbsp;&nbsp;</span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="stl_ stl_02"
      style="
        font-family: 'Times New Roman';
        font-size: medium;
        white-space: normal;
      "
    >
      <div class="stl_view">
        <p><br /></p>
        <div class="stl_01" style="left: 7.5em; top: 6.1095em">
          <span class="stl_07 stl_08 stl_17"
            >我们会通过如下渠道获取你的个人信息： &nbsp;</span
          >
        </div>
        <div class="stl_01" style="left: 7.5em; top: 8.2895em">
          <span class="stl_07 stl_08 stl_18" style="font-weight: bold"
            >（一）你直接提供给我们及我们收集的个人信息 &nbsp;</span
          >
        </div>
        <p style="left: 7.5em; top: 10.4595em">
          <span class="stl_07 stl_08 stl_18" style="font-weight: bold"
            >请你注意，如果你提供的是他人个人信息，请你确保已取得相关主体的授权。&nbsp;&nbsp;</span
          >
        </p>
        <p style="left: 7.5em; top: 10.4595em">
          <span class="stl_07 stl_08 stl_18" style="font-weight: bold"></span>
        </p>
        <div
          class="stl_ stl_02"
          style="
            font-family: 'Times New Roman';
            font-size: medium;
            white-space: normal;
          "
        >
          <div class="stl_view">
            <div class="stl_05 stl_06">
              <div class="stl_01" style="left: 7.5em; top: 10.4595em">
                <span class="stl_07 stl_08 stl_18" style="font-weight: bold"
                  >&nbsp;&nbsp;</span
                >
              </div>
              <table
                cellspacing="0"
                cellpadding="0"
                style="margin-left: 0pt; width: 428.8pt"
              >
                <tbody style="width: 571.5px; display: block">
                  <tr>
                    <td
                      style="
                        background-color: #ffffff;
                        border-color: #dddddd;
                        border-style: solid;
                        border-width: 0.75pt;
                        padding: 3.38pt 7.12pt 3.38pt 4.88pt;
                        vertical-align: middle;
                        width: 154.008px;
                      "
                    >
                      <p style="margin: 0pt 0pt 10.5pt">
                        <span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            font-weight: bold;
                            vertical-align: baseline;
                          "
                          >我们收集的个人信息</span
                        >
                      </p>
                    </td>
                    <td
                      style="
                        background-color: #ffffff;
                        border-color: #dddddd;
                        border-style: solid;
                        border-width: 0.75pt;
                        padding: 3.38pt 7.12pt 3.38pt 4.88pt;
                        vertical-align: middle;
                        width: 113.45pt;
                      "
                    >
                      <p style="margin: 0pt 0pt 10.5pt">
                        <span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            font-weight: bold;
                            vertical-align: baseline;
                          "
                          >使用目的</span
                        >
                      </p>
                    </td>
                    <td
                      style="
                        background-color: #ffffff;
                        border-color: #dddddd;
                        border-style: solid;
                        border-width: 0.75pt;
                        padding: 3.38pt 7.12pt 3.38pt 4.88pt;
                        vertical-align: middle;
                        width: 56.05pt;
                      "
                    >
                      <p style="margin: 0pt 0pt 10.5pt">
                        <span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            font-weight: bold;
                            vertical-align: baseline;
                          "
                          >收集场景</span
                        >
                      </p>
                    </td>
                    <td
                      style="
                        background-color: #ffffff;
                        border-color: #dddddd;
                        border-style: solid;
                        border-width: 0.75pt;
                        padding: 3.38pt 7.12pt 3.38pt 4.88pt;
                        vertical-align: middle;
                        width: 57.05pt;
                      "
                    >
                      <p style="margin: 0pt 0pt 10.5pt">
                        <span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            font-weight: bold;
                            vertical-align: baseline;
                          "
                          >法律依据</span
                        >
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        background-color: #ffffff;
                        border-color: #dddddd;
                        border-style: solid;
                        border-width: 0.75pt;
                        padding: 3.38pt 7.12pt 3.38pt 4.88pt;
                        vertical-align: middle;
                        width: 154.008px;
                      "
                    >
                      <p style="margin: 0pt 0pt 10.5pt">
                        <span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            font-weight: bold;
                            vertical-align: baseline;
                          "
                          >帐号注册信息及联系方式</span
                        >
                      </p>
                      <p style="margin: 0pt 0pt 10.5pt">
                        <span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            font-weight: bold;
                            vertical-align: baseline;
                          "
                          >包括：</span
                        ><span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            font-weight: bold;
                            vertical-align: baseline;
                          "
                          >微信</span
                        ><span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            vertical-align: baseline;
                          "
                          >账</span
                        ><span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            vertical-align: baseline;
                          "
                          >号</span
                        ><span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            vertical-align: baseline;
                          "
                          >名字、</span
                        ><span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            vertical-align: baseline;
                          "
                          >本人的手机号码。</span
                        >
                      </p>
                    </td>
                    <td
                      style="
                        background-color: #ffffff;
                        border-color: #dddddd;
                        border-style: solid;
                        border-width: 0.75pt;
                        padding: 3.38pt 7.12pt 3.38pt 4.88pt;
                        vertical-align: middle;
                        width: 113.45pt;
                      "
                    >
                      <p style="margin: 0pt 0pt 10.5pt">
                        <span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            vertical-align: baseline;
                          "
                          >提供新用户注册</span
                        >
                      </p>
                      <p style="margin: 0pt 0pt 10.5pt">
                        <span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            vertical-align: baseline;
                          "
                          >提供老用户登录</span
                        >
                      </p>
                      <p style="margin: 0pt 0pt 10.5pt">
                        <span style="font-family: Calibri; font-size: 12pt"
                          >&nbsp;</span
                        >
                      </p>
                    </td>
                    <td
                      style="
                        background-color: #ffffff;
                        border-color: #dddddd;
                        border-style: solid;
                        border-width: 0.75pt;
                        padding: 3.38pt 7.12pt 3.38pt 4.88pt;
                        vertical-align: middle;
                        width: 56.05pt;
                      "
                    >
                      <p style="margin: 0pt 0pt 10.5pt; text-align: center">
                        <span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            vertical-align: baseline;
                          "
                          >注册/登录</span
                        >
                      </p>
                    </td>
                    <td
                      style="
                        background-color: #ffffff;
                        border-color: #dddddd;
                        border-style: solid;
                        border-width: 0.75pt;
                        padding: 3.38pt 7.12pt 3.38pt 4.88pt;
                        vertical-align: middle;
                        width: 57.05pt;
                      "
                    >
                      <p style="margin: 0pt 0pt 10.5pt; text-align: justify">
                        <span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            vertical-align: baseline;
                          "
                          >履行合同</span
                        >
                      </p>
                      <p style="margin: 0pt 0pt 10.5pt; text-align: justify">
                        <span
                          style="
                            color: #333333;
                            font-family: Helvetica;
                            font-size: 12pt;
                            vertical-align: baseline;
                          "
                          >&nbsp;</span
                        >
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        background-color: #ffffff;
                        border-color: #dddddd;
                        border-style: solid;
                        border-width: 0.75pt;
                        padding: 3.38pt 7.12pt 3.38pt 4.88pt;
                        vertical-align: middle;
                        width: 154.008px;
                      "
                    >
                      <p style="margin: 0pt 0pt 10.5pt">
                        <span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            font-weight: bold;
                            vertical-align: baseline;
                          "
                          >个人姓名和订单</span
                        ><span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            font-weight: bold;
                            vertical-align: baseline;
                          "
                          >信息</span
                        >
                      </p>
                      <p style="margin: 0pt 0pt 10.5pt">
                        <span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            font-weight: bold;
                            vertical-align: baseline;
                          "
                          >包括：</span
                        ><span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            font-weight: bold;
                            vertical-align: baseline;
                          "
                          >个人姓名、</span
                        ><span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            vertical-align: baseline;
                          "
                          >订单号、</span
                        ><span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            vertical-align: baseline;
                          "
                          >订单信息、</span
                        ><span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            vertical-align: baseline;
                          "
                          >交易金额</span
                        ><span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            vertical-align: baseline;
                          "
                          >等</span
                        ><span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            vertical-align: baseline;
                          "
                          >信息</span
                        ><span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            vertical-align: baseline;
                          "
                          >。</span
                        >
                      </p>
                    </td>
                    <td
                      style="
                        background-color: #ffffff;
                        border-color: #dddddd;
                        border-style: solid;
                        border-width: 0.75pt;
                        padding: 3.38pt 7.12pt 3.38pt 4.88pt;
                        vertical-align: middle;
                        width: 113.45pt;
                      "
                    >
                      <p style="margin: 0pt 0pt 10.5pt">
                        <span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            vertical-align: baseline;
                          "
                          >订单审核</span
                        >
                      </p>
                    </td>
                    <td
                      style="
                        background-color: #ffffff;
                        border-color: #dddddd;
                        border-style: solid;
                        border-width: 0.75pt;
                        padding: 3.38pt 7.12pt 3.38pt 4.88pt;
                        vertical-align: middle;
                        width: 56.05pt;
                      "
                    >
                      <p style="margin: 0pt 0pt 10.5pt; text-align: justify">
                        <span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            vertical-align: baseline;
                          "
                          >订单审核</span
                        >
                      </p>
                    </td>
                    <td
                      style="
                        background-color: #ffffff;
                        border-color: #dddddd;
                        border-style: solid;
                        border-width: 0.75pt;
                        padding: 3.38pt 7.12pt 3.38pt 4.88pt;
                        vertical-align: middle;
                        width: 57.05pt;
                      "
                    >
                      <p style="margin: 0pt 0pt 10.5pt; text-align: justify">
                        <span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            vertical-align: baseline;
                          "
                          >履行合同</span
                        >
                      </p>
                      <p style="margin: 0pt 0pt 10.5pt; text-align: justify">
                        <span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            vertical-align: baseline;
                          "
                          >履行法定职责或法定义务</span
                        >
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        background-color: #ffffff;
                        border-color: #dddddd;
                        border-style: solid;
                        border-width: 0.75pt;
                        padding: 3.38pt 7.12pt 3.38pt 4.88pt;
                        vertical-align: middle;
                        width: 154.008px;
                      "
                    >
                      <p style="margin: 0pt 0pt 10.5pt">
                        <span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            font-weight: bold;
                            vertical-align: baseline;
                          "
                          >反馈信息</span
                        >
                      </p>
                      <p style="margin: 0pt 0pt 10.5pt">
                        <span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            font-weight: bold;
                            vertical-align: baseline;
                          "
                          >包括：</span
                        ><span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            vertical-align: baseline;
                          "
                          >你通过电话以及其他形式调查提交给我们的信息。</span
                        >
                      </p>
                    </td>
                    <td
                      style="
                        background-color: #ffffff;
                        border-color: #dddddd;
                        border-style: solid;
                        border-width: 0.75pt;
                        padding: 3.38pt 7.12pt 3.38pt 4.88pt;
                        vertical-align: middle;
                        width: 113.45pt;
                      "
                    >
                      <p style="margin: 0pt 0pt 10.5pt">
                        <span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            vertical-align: baseline;
                          "
                          >提升用户体验</span
                        >
                      </p>
                      <p style="margin: 0pt 0pt 10.5pt">
                        <span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            vertical-align: baseline;
                          "
                          >解决交易纠纷</span
                        >
                      </p>
                      <p style="margin: 0pt 0pt 10.5pt">
                        <span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            vertical-align: baseline;
                          "
                          >保障消费者个人权益</span
                        >
                      </p>
                    </td>
                    <td
                      style="
                        background-color: #ffffff;
                        border-color: #dddddd;
                        border-style: solid;
                        border-width: 0.75pt;
                        padding: 3.38pt 7.12pt 3.38pt 4.88pt;
                        vertical-align: middle;
                        width: 56.05pt;
                      "
                    >
                      <p style="margin: 0pt 0pt 10.5pt; text-align: center">
                        <span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            vertical-align: baseline;
                          "
                          >联系客服</span
                        >
                      </p>
                    </td>
                    <td
                      style="
                        background-color: #ffffff;
                        border-color: #dddddd;
                        border-style: solid;
                        border-width: 0.75pt;
                        padding: 3.38pt 7.12pt 3.38pt 4.88pt;
                        vertical-align: middle;
                        width: 57.05pt;
                      "
                    >
                      <p style="margin: 0pt 0pt 10.5pt; text-align: center">
                        <span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            vertical-align: baseline;
                          "
                          >取得个人的同意</span
                        >
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        background-color: #ffffff;
                        border-color: #dddddd;
                        border-style: solid;
                        border-width: 0.75pt;
                        padding: 3.38pt 7.12pt 3.38pt 4.88pt;
                        vertical-align: middle;
                        width: 154.008px;
                      "
                    >
                      <p style="margin: 0pt 0pt 10.5pt">
                        <span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            font-weight: bold;
                            vertical-align: baseline;
                          "
                          >位置信息</span
                        >
                      </p>
                      <p style="margin: 0pt 0pt 10.5pt">
                        <span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            font-weight: bold;
                            vertical-align: baseline;
                          "
                          >包括：</span
                        ><span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            vertical-align: baseline;
                          "
                          >与你的IP地址关联的或通过Wi-Fi三角测量定位得到的城市、省和邮政编码。</span
                        >
                      </p>
                      <p style="margin: 0pt 0pt 10.5pt">
                        <span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            vertical-align: baseline;
                          "
                          >在通过你移动设备上基于GPS的功能使用你的精确位置之前，我们将请求你的授权。</span
                        >
                      </p>
                    </td>
                    <td
                      style="
                        background-color: #ffffff;
                        border-color: #dddddd;
                        border-style: solid;
                        border-width: 0.75pt;
                        padding: 3.38pt 7.12pt 3.38pt 4.88pt;
                        vertical-align: middle;
                        width: 113.45pt;
                      "
                    >
                      <p style="margin: 0pt 0pt 10.5pt">
                        <span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            vertical-align: baseline;
                          "
                          >保障帐号安全性</span
                        >
                      </p>
                      <p style="margin: 0pt 0pt 10.5pt">
                        <span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            vertical-align: baseline;
                          "
                          >显示商品距离</span
                        >
                      </p>
                    </td>
                    <td
                      style="
                        background-color: #ffffff;
                        border-color: #dddddd;
                        border-style: solid;
                        border-width: 0.75pt;
                        padding: 3.38pt 7.12pt 3.38pt 4.88pt;
                        vertical-align: middle;
                        width: 56.05pt;
                      "
                    >
                      <p style="margin: 0pt 0pt 10.5pt; text-align: center">
                        <span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            vertical-align: baseline;
                          "
                          >注册</span
                        ><span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            vertical-align: baseline;
                          "
                          >/</span
                        ><span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            vertical-align: baseline;
                          "
                          >登录</span
                        >
                      </p>
                      <p style="margin: 0pt 0pt 10.5pt; text-align: center">
                        <span style="font-family: Calibri; font-size: 12pt"
                          >&nbsp;</span
                        >
                      </p>
                    </td>
                    <td
                      style="
                        background-color: #ffffff;
                        border-color: #dddddd;
                        border-style: solid;
                        border-width: 0.75pt;
                        padding: 3.38pt 7.12pt 3.38pt 4.88pt;
                        vertical-align: middle;
                        width: 57.05pt;
                      "
                    >
                      <p style="margin: 0pt 0pt 10.5pt; text-align: center">
                        <span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            vertical-align: baseline;
                          "
                          >取得个人的同意</span
                        >
                      </p>
                      <p style="margin: 0pt 0pt 10.5pt; text-align: center">
                        <span
                          style="
                            color: #333333;
                            font-family: 黑体;
                            font-size: 11pt;
                            vertical-align: baseline;
                          "
                          >履行法定职责或法定义务</span
                        >
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="stl_01" style="left: 7.5em; top: 50.0395em">
                <span class="stl_07 stl_08 stl_27" style="font-weight: bold"
                  >（二）征得同意的例外 &nbsp;</span
                >
              </div>
              <div class="stl_01" style="left: 7.5em; top: 52.2095em">
                <span class="stl_07 stl_08 stl_50"
                  >你应充分知晓，以下情形中，我们收集、使用个人信息无需征得你的授权同意：
                  &nbsp;</span
                >
              </div>
              <div class="stl_01" style="left: 7.5em; top: 54.3895em">
                <span class="stl_07 stl_08 stl_50"
                  >1.与国家安全、国防安全有关的； &nbsp;</span
                >
              </div>
              <div class="stl_01" style="left: 7.5em; top: 56.5595em">
                <span class="stl_07 stl_08 stl_17"
                  >2.与公共安全、公共卫生、重大公共利益有关的； &nbsp;</span
                >
              </div>
              <div class="stl_01" style="left: 7.5em; top: 58.7395em">
                <span class="stl_07 stl_08 stl_17"
                  >3.与犯罪侦查、起诉、审判和判决执行等有关的； &nbsp;</span
                >
              </div>
              <div class="stl_01" style="left: 7.5em; top: 60.9095em">
                <span class="stl_07 stl_08 stl_27"
                  >4.出于维护个人信息主体或其他个人的生命</span
                ><span class="stl_07 stl_08 stl_51">、</span
                ><span class="stl_07 stl_08 stl_09">财</span
                ><span class="stl_07 stl_08 stl_49"
                  >产等重大合法权益但又很难得到本人同</span
                >意的；&nbsp;&nbsp;
              </div>
            </div>
          </div>
        </div>
        <div
          class="stl_ stl_02"
          style="
            font-family: 'Times New Roman';
            font-size: medium;
            white-space: normal;
          "
        >
          <p>
            5.所收集的个人信息是个人信息主体自行向社会公众公开的；&nbsp;&nbsp;
          </p>
          <div class="stl_01" style="left: 7.5em; top: 8.2895em">
            <span class="stl_07 stl_08 stl_52"
              >6.从合法公开披露的信息中收集的你的个人信息的，如合法的新闻报道、政府信息公开&nbsp;&nbsp;</span
            >等渠道；但是你明确拒绝或者处理该信息侵害你重大利益的除外。&nbsp;&nbsp;
          </div>
          <div class="stl_01" style="left: 7.5em; top: 11.7595em">
            <span class="stl_07 stl_08 stl_35"
              >7.根据你的要求签订合同所必需的； &nbsp;</span
            >
          </div>
          <div class="stl_01" style="left: 7.5em; top: 13.9395em">
            <span class="stl_07 stl_08 stl_20"
              >8.用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与</span
            >服务的故障；&nbsp;&nbsp;
          </div>
          <div class="stl_01" style="left: 7.5em; top: 17.4095em">
            <span class="stl_07 stl_08 stl_29"
              >9.为合法的新闻报道所必需的； &nbsp;</span
            >
          </div>
          <div class="stl_01" style="left: 7.5em; top: 19.5895em">
            <span class="stl_07 stl_08 stl_50"
              >10.学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描&nbsp;&nbsp;</span
            >述的结果时，对结果中所包含的个人信息进行去标识化处理的；&nbsp;&nbsp;
          </div>
          <div class="stl_01" style="left: 7.5em; top: 23.0595em">
            <span class="stl_07 stl_08 stl_10"
              >11.法律法规规定的其他情形。 &nbsp;</span
            >
          </div>
          <div class="stl_01" style="left: 7.5em; top: 25.2395em">
            <span class="stl_07 stl_08 stl_10" style="font-weight: bold"
              >（三）个人信息使用规则 &nbsp;</span
            >
          </div>
          <div class="stl_01" style="left: 7.5em; top: 27.4095em; z-index: 297">
            <span class="stl_07 stl_08 stl_50"
              >1.我们会根据本隐私声明的约定</span
            ><span class="stl_07 stl_08 stl_53">，</span
            ><span class="stl_07 stl_08 stl_09">为</span
            ><span class="stl_07 stl_08 stl_50"
              >实现我们的产品与/或服务功能而对所收集的个人信&nbsp;&nbsp;</span
            >息进行使用。&nbsp;&nbsp;
          </div>
          <p style="left: 7.5em; top: 30.8895em">
            <span class="stl_07 stl_08 stl_52"
              >2.在收集你的个人信息后，我们将通过技术手段对信息进行去标识化处理，去标识化处&nbsp;&nbsp;</span
            ><span class="stl_07 stl_08 stl_35">理的信息将无法识别主体</span
            ><span class="stl_07 stl_08 stl_54">。</span
            ><span class="stl_07 stl_08 stl_55">请你了解并同意</span
            ><span class="stl_07 stl_08 stl_56">，</span
            ><span class="stl_07 stl_08 stl_09">在</span
            ><span class="stl_07 stl_08 stl_18"
              >此情况下我们有权使用已经去标识化的&nbsp;&nbsp;</span
            ><span class="stl_07 stl_08 stl_15">信息</span
            ><span class="stl_07 stl_08 stl_54">；</span
            ><span class="stl_07 stl_08 stl_09">并</span
            ><span class="stl_07 stl_08 stl_57">在不透露你个人信息的前提下</span
            ><span class="stl_07 stl_08 stl_58">，</span
            ><span class="stl_07 stl_08 stl_09">我</span
            ><span class="stl_07 stl_08 stl_59"
              >们有权对用户数据库进行分析并予以商业化&nbsp;&nbsp;</span
            >的利用。&nbsp;&nbsp;
          </p>
          <p style="left: 7.5em; top: 30.8895em"><br /></p>
          <div
            class="stl_ stl_02"
            style="
              font-family: 'Times New Roman';
              font-size: medium;
              white-space: normal;
            "
          >
            <div class="stl_view">
              <div class="stl_05 stl_06">
                <div class="stl_01" style="left: 7.5em; top: 46.0595em">
                  <span class="stl_07 stl_08 stl_18" style="font-weight: bold"
                    >（二）转让 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 48.2395em">
                  <span class="stl_07 stl_08 stl_29"
                    >我们不会将你的个人信息转让给任何公司、组织和个人，但以下情况除外：
                    &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 50.4095em">
                  <span class="stl_07 stl_08 stl_50"
                    >1.事先获得你明确的同意或授权； &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 52.5895em">
                  <span class="stl_07 stl_08 stl_64"
                    >2.根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行</span
                  >提供；&nbsp;&nbsp;
                </div>
                <div class="stl_01" style="left: 7.5em; top: 56.0595em">
                  <span class="stl_07 stl_08 stl_52"
                    >3.符合与你签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的</span
                  >法律文件约定所提供；&nbsp;&nbsp;
                </div>
                <div class="stl_01" style="left: 7.5em; top: 59.5395em">
                  <span class="stl_07 stl_08 stl_52"
                    >4.随着我们业务的发展，我们及我们的关联方有可能进行合并、收购、资产转让或类似&nbsp;&nbsp;</span
                  ><span class="stl_07 stl_08 stl_20">的交易</span
                  ><span class="stl_07 stl_08 stl_68">，</span
                  ><span class="stl_07 stl_08 stl_64" style="font-weight: bold"
                    >如涉及个人信息的转让，我们会要求受让你个人信息的公司</span
                  ><span class="stl_07 stl_08 stl_68" style="font-weight: bold"
                    >、</span
                  ><span class="stl_07 stl_08 stl_09" style="font-weight: bold"
                    >组</span
                  ><span class="stl_07 stl_08 stl_25" style="font-weight: bold"
                    >织继续接受本</span
                  ><span style="font-weight: bold"
                    >隐私声明的约束，否则,我们将要求该公司、组织重新征求你的授权同意。&nbsp;&nbsp;</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="stl_ stl_02"
            style="
              font-family: 'Times New Roman';
              font-size: medium;
              white-space: normal;
            "
          >
            <div class="stl_view">
              <div class="stl_05 stl_06">
                <div class="stl_01" style="left: 7.5em; top: 6.1095em">
                  <span class="stl_07 stl_08 stl_40" style="font-weight: bold"
                    >（三）公开披露 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 8.2895em">
                  <span class="stl_07 stl_08 stl_20"
                    >我们仅会在以下情况下，公开披露你的个人信息： &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 10.4595em">
                  <span class="stl_07 stl_08 stl_27"
                    >1.获得你明确同意或基于你的主动选择，我们会公开披露你的个人信息；
                    &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 12.6395em">
                  <span class="stl_07 stl_08 stl_64"
                    >2.根据法律、法规的要求、强制性的行政执法或司法要求所必须提供你个人信息的情况</span
                  ><span class="stl_07 stl_08 stl_09">下</span
                  ><span class="stl_07 stl_08 stl_11">，</span
                  ><span class="stl_07 stl_08 stl_09">我</span
                  ><span class="stl_07 stl_08 stl_27"
                    >们会依据所要求的个人信息类型和披露方式公开披露你的个人信息</span
                  ><span class="stl_07 stl_08 stl_11">。</span
                  ><span class="stl_07 stl_08 stl_09">在</span
                  ><span class="stl_07 stl_08 stl_20">符合法律</span
                  ><span class="stl_07 stl_08 stl_20">法规的前提下</span
                  ><span class="stl_07 stl_08 stl_11">，</span
                  ><span class="stl_07 stl_08 stl_09">当</span
                  ><span class="stl_07 stl_08 stl_17"
                    >我们收到上述披露信息的请求时</span
                  ><span class="stl_07 stl_08 stl_13">，</span
                  ><span class="stl_07 stl_08 stl_09">我</span
                  ><span class="stl_07 stl_08 stl_12"
                    >们会要求必须出具与之相应的法</span
                  >律文件，如传票或调查函，并且我们披露的文件均是加密后提供。&nbsp;&nbsp;
                </div>
                <div class="stl_01" style="left: 7.5em; top: 18.7095em">
                  <span class="stl_07 stl_08 stl_18" style="font-weight: bold"
                    >（四）共享、转让、公开披露个人信息时事先征得授权同意的例外
                    &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 20.8895em">
                  <span class="stl_07 stl_08 stl_50"
                    >以下情形中，共享、转让、公开披露你的个人信息无需事先征得你的授权同意：
                    &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 23.0595em">
                  <span class="stl_07 stl_08 stl_17"
                    >1.与我们履行法律法规规定的义务相关的； &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 25.2395em">
                  <span class="stl_07 stl_08 stl_29"
                    >2.根据法律法规的规定或行政、司法机构、国家部门等有权机关的要求依法提供的；
                    &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 27.4095em">
                  <span class="stl_07 stl_08 stl_20"
                    >3.出于维护你或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
                    &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 29.5895em">
                  <span class="stl_07 stl_08 stl_29"
                    >4.你自行向社会公众公开的个人信息； &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 31.7595em">
                  <span class="stl_07 stl_08 stl_29"
                    >5.与国家安全、国防安全直接相关的； &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 33.9395em">
                  <span class="stl_07 stl_08 stl_64"
                    >6.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道</span
                  ><span class="stl_07 stl_08 stl_20"
                    >根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原&nbsp;&nbsp;</span
                  ><span class="stl_07 stl_08 stl_24"
                    >并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此&nbsp;&nbsp;</span
                  >类数据的保存及处理将无需另行向你通知并征得你的同意。&nbsp;&nbsp;
                </div>
                <div class="stl_01" style="left: 7.5em; top: 41.7595em">
                  <span
                    class="stl_07 stl_08 stl_65"
                    style="font-weight: bold; word-spacing: 0.0016em"
                    >7.以下为汉鼎科技客户端接入第三方 SDK 目录与其所使用于的业务：
                    &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 9.34em; top: 43.9395em">
                  <span
                    class="stl_07 stl_08 stl_26"
                    style="word-spacing: 0.2467em"
                    >7.1 第三方公司名称：深圳市腾讯计算机系统有限公司
                    &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 11.17em; top: 46.1095em">
                  <span
                    class="stl_07 stl_08 stl_23"
                    style="word-spacing: 0.2645em"
                    >产品类型: 微信</span
                  ><span
                    class="stl_07 stl_08 stl_45"
                    style="word-spacing: -0.0067em"
                    >&nbsp;</span
                  ><span class="stl_07 stl_08 stl_69">SDK &nbsp;</span>
                </div>
                <div class="stl_01" style="left: 11.17em; top: 48.2895em">
                  <span
                    class="stl_07 stl_08 stl_17"
                    style="word-spacing: 0.2525em"
                    >共享信息名称: 设备信息、本机号码 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 11.17em; top: 50.4595em">
                  <span
                    class="stl_07 stl_08 stl_12"
                    style="word-spacing: 0.2642em"
                    >使用目的: 实现将内容分享到微信 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 11.17em; top: 52.6395em">
                  <span
                    class="stl_07 stl_08 stl_49"
                    style="word-spacing: 0.264em"
                    >使用场景: 微信授权登录，微信好友和微信朋友圈分享功能
                    &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 11.17em; top: 54.8095em">
                  <span
                    class="stl_07 stl_08 stl_23"
                    style="word-spacing: 0.2645em"
                    >共享方式: 内嵌</span
                  ><span
                    class="stl_07 stl_08 stl_45"
                    style="word-spacing: -0.0067em"
                    >&nbsp;</span
                  ><span class="stl_07 stl_08 stl_69">SDK &nbsp;</span>
                </div>
                <div class="stl_01" style="left: 11.17em; top: 56.9895em">
                  <a href="https://privacy.qq.com/" target="_blank"
                    ><span
                      class="stl_07 stl_08 stl_25"
                      style="word-spacing: 0.2492em"
                      >第三方个人信息处理规则: https://privacy.qq.com/
                      &nbsp;</span
                    ></a
                  >
                </div>
                <div class="stl_01" style="left: 9.3299em; top: 59.1595em">
                  <span
                    class="stl_07 stl_08 stl_26"
                    style="word-spacing: 0.2577em"
                    >7.2 第三方公司名称：深圳市腾讯计算机系统有限公司
                    &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 11.17em; top: 61.3395em">
                  <span
                    class="stl_07 stl_08 stl_23"
                    style="word-spacing: 0.2645em"
                    >产品类型: 腾讯</span
                  ><span
                    class="stl_07 stl_08 stl_45"
                    style="word-spacing: -0.0067em"
                    >&nbsp;</span
                  ><span
                    class="stl_07 stl_08 stl_55"
                    style="word-spacing: -0.0017em"
                    >TPNS 推送 &nbsp;</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="stl_ stl_02"
            style="
              font-family: 'Times New Roman';
              font-size: medium;
              white-space: normal;
            "
          >
            <div class="stl_view">
              <div class="stl_05 stl_06">
                <div class="stl_01" style="left: 11.17em; top: 6.1095em">
                  <span
                    class="stl_07 stl_08 stl_29"
                    style="word-spacing: 0.2523em"
                    >共享信息名称: 设备信息、网络状态信息、存储信息、位置信息
                    &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 11.17em; top: 8.2895em">
                  <span
                    class="stl_07 stl_08 stl_29"
                    style="word-spacing: 0.2634em"
                    >使用目的: 实现将用户订单信息等内容推送到手机</span
                  ><span
                    class="stl_07 stl_08 stl_45"
                    style="word-spacing: -0.0069em"
                    >&nbsp;</span
                  ><span
                    class="stl_07 stl_08 stl_70"
                    style="word-spacing: -0.0021em"
                    >App 用户 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 11.17em; top: 10.4595em">
                  <span
                    class="stl_07 stl_08 stl_63"
                    style="word-spacing: 0.2643em"
                    >使用场景: 用于将用户订单信息进行消息推送 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 11.17em; top: 12.6395em">
                  <span
                    class="stl_07 stl_08 stl_23"
                    style="word-spacing: 0.2645em"
                    >共享方式: 内嵌</span
                  ><span
                    class="stl_07 stl_08 stl_45"
                    style="word-spacing: -0.0067em"
                    >&nbsp;</span
                  ><span class="stl_07 stl_08 stl_69">SDK &nbsp;</span>
                </div>
                <div class="stl_01" style="left: 11.17em; top: 14.8095em">
                  <span class="stl_07 stl_08 stl_35"
                    >第三方个人信息处理规则: &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 16.1095em">
                  <a
                    href="https://privacy.qq.com/document/preview/8565a4a2d26e480187ed86b0cc81d"
                    target="_blank"
                    ><span class="stl_07 stl_08 stl_55"
                      >https://privacy.qq.com/document/preview/8565a4a2d26e480187ed86b0cc81d
                      &nbsp;</span
                    ></a
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 17.4095em">
                  <span class="stl_07 stl_08 stl_40">727 &nbsp;</span>
                </div>
                <div class="stl_01" style="left: 9.3299em; top: 19.5895em">
                  <span
                    class="stl_07 stl_08 stl_26"
                    style="word-spacing: 0.2577em"
                    >7.3 第三方公司名称：深圳市腾讯计算机系统有限公司
                    &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 11.17em; top: 21.7595em">
                  <span
                    class="stl_07 stl_08 stl_23"
                    style="word-spacing: 0.2645em"
                    >产品类型: 腾讯</span
                  ><span
                    class="stl_07 stl_08 stl_45"
                    style="word-spacing: -0.0067em"
                    >&nbsp;</span
                  ><span class="stl_07 stl_08 stl_61">Bugly &nbsp;</span>
                </div>
                <div class="stl_01" style="left: 11.17em; top: 23.9395em">
                  <span
                    class="stl_07 stl_08 stl_29"
                    style="word-spacing: 0.2523em"
                    >共享信息名称: 设备信息、网络状态信息、存储信息、位置信息
                    &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 11.17em; top: 26.1095em">
                  <span
                    class="stl_07 stl_08 stl_14"
                    style="word-spacing: 0.2652em"
                    >使用目的: 获取手机</span
                  ><span
                    class="stl_07 stl_08 stl_45"
                    style="word-spacing: -0.0063em"
                    >&nbsp;</span
                  ><span
                    class="stl_07 stl_08 stl_59"
                    style="word-spacing: 0.001em"
                    >App 内错误信息,帮助用户获取更好的体验 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 11.17em; top: 28.2895em">
                  <span
                    class="stl_07 stl_08 stl_23"
                    style="word-spacing: 0.2645em"
                    >使用场景: 用户</span
                  ><span
                    class="stl_07 stl_08 stl_45"
                    style="word-spacing: -0.0067em"
                    >&nbsp;</span
                  ><span
                    class="stl_07 stl_08 stl_27"
                    style="word-spacing: 0.0024em"
                    >App 崩溃出现错误后将错误信息上传到服务器以便开发者修</span
                  >复
                </div>
                <div class="stl_01" style="left: 11.17em; top: 31.7595em">
                  <span
                    class="stl_07 stl_08 stl_23"
                    style="word-spacing: 0.2645em"
                    >共享方式: 内嵌</span
                  ><span
                    class="stl_07 stl_08 stl_45"
                    style="word-spacing: -0.0067em"
                    >&nbsp;</span
                  ><span class="stl_07 stl_08 stl_69">SDK &nbsp;</span>
                </div>
                <div class="stl_01" style="left: 11.17em; top: 33.9395em">
                  <span class="stl_07 stl_08 stl_35"
                    >第三方个人信息处理规则: &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 35.2395em">
                  <a
                    href="https://privacy.qq.com/document/preview/fc748b3d96224fdb825ea79e132c1a"
                    target="_blank"
                    ><span class="stl_07 stl_08 stl_61"
                      >https://privacy.qq.com/document/preview/fc748b3d96224fdb825ea79e132c1a
                      &nbsp;</span
                    ></a
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 36.5395em">
                  <span class="stl_07 stl_08 stl_40">56 &nbsp;</span>
                </div>
                <div class="stl_01" style="left: 9.3299em; top: 38.7095em">
                  <span
                    class="stl_07 stl_08 stl_26"
                    style="word-spacing: 0.2577em"
                    >7.4 第三方公司名称：深圳市腾讯计算机系统有限公司
                    &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 11.17em; top: 40.8895em">
                  <span
                    class="stl_07 stl_08 stl_14"
                    style="word-spacing: 0.2652em"
                    >产品类型: 腾讯地图 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 11.17em; top: 43.0595em">
                  <span
                    class="stl_07 stl_08 stl_29"
                    style="word-spacing: 0.2523em"
                    >共享信息名称: 设备信息、网络状态信息、存储信息、位置信息
                    &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 11.17em; top: 45.2395em">
                  <span
                    class="stl_07 stl_08 stl_35"
                    style="word-spacing: 0.2639em"
                    >使用目的: 获取用户所在位置,为用户提供更精准的服务
                    &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 11.17em; top: 47.4095em">
                  <span
                    class="stl_07 stl_08 stl_23"
                    style="word-spacing: 0.2645em"
                    >使用场景: 用于在用户进入</span
                  ><span
                    class="stl_07 stl_08 stl_45"
                    style="word-spacing: -0.0066em"
                    >&nbsp;</span
                  ><span
                    class="stl_07 stl_08 stl_34"
                    style="word-spacing: 0.0011em"
                    >app
                    之后获取当前位置,提供当前位置该用户能参&nbsp;&nbsp;</span
                  >加的活动&nbsp;&nbsp;
                </div>
                <div class="stl_01" style="left: 11.17em; top: 50.8895em">
                  <span
                    class="stl_07 stl_08 stl_23"
                    style="word-spacing: 0.2645em"
                    >共享方式: 内嵌</span
                  ><span
                    class="stl_07 stl_08 stl_45"
                    style="word-spacing: -0.0067em"
                    >&nbsp;</span
                  ><span class="stl_07 stl_08 stl_69">SDK &nbsp;</span>
                </div>
                <div class="stl_01" style="left: 11.17em; top: 53.0595em">
                  <span class="stl_07 stl_08 stl_35"
                    >第三方个人信息处理规则: &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 54.3595em">
                  <a
                    href="https://privacy.qq.com/document/preview/dbd484ce652c486cb6d7e43ef12cef"
                    target="_blank"
                    ><span class="stl_07 stl_08 stl_61"
                      >https://privacy.qq.com/document/preview/dbd484ce652c486cb6d7e43ef12cef
                      &nbsp;</span
                    ></a
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 55.6595em">
                  <span class="stl_07 stl_08 stl_71">b0 &nbsp;</span>
                </div>
                <div class="stl_01" style="left: 9.3299em; top: 57.8395em">
                  <span
                    class="stl_07 stl_08 stl_72"
                    style="word-spacing: 0.2573em"
                    >7.5 第三方公司名称：北京小米移动软件有限公司 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 11.17em; top: 60.0095em">
                  <span
                    class="stl_07 stl_08 stl_14"
                    style="word-spacing: 0.2652em"
                    >产品类型: 小米推送</span
                  ><span
                    class="stl_07 stl_08 stl_45"
                    style="word-spacing: -0.0063em"
                    >&nbsp;</span
                  ><span class="stl_07 stl_08 stl_69">SDK &nbsp;</span>
                </div>
                <div class="stl_01" style="left: 11.17em; top: 62.1895em">
                  <span
                    class="stl_07 stl_08 stl_20"
                    style="word-spacing: 0.252em"
                    >共享信息名称: 设备信息、网络状态信息、存储信息 &nbsp;</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="stl_ stl_02"
            style="
              font-family: 'Times New Roman';
              font-size: medium;
              white-space: normal;
            "
          >
            <div class="stl_view">
              <div class="stl_05 stl_06">
                <div class="stl_01" style="left: 11.17em; top: 6.1095em">
                  <span
                    class="stl_07 stl_08 stl_63"
                    style="word-spacing: 0.2643em"
                    >使用目的: 实现将订单等内容推送到小米用户 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 11.17em; top: 8.2895em">
                  <span
                    class="stl_07 stl_08 stl_12"
                    style="word-spacing: 0.2641em"
                    >使用场景: 用于实现对小米品牌的手机进行消息推送 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 11.17em; top: 10.4595em">
                  <span
                    class="stl_07 stl_08 stl_23"
                    style="word-spacing: 0.2645em"
                    >共享方式: 内嵌</span
                  ><span
                    class="stl_07 stl_08 stl_45"
                    style="word-spacing: -0.0067em"
                    >&nbsp;</span
                  ><span class="stl_07 stl_08 stl_69">SDK &nbsp;</span>
                </div>
                <div class="stl_01" style="left: 11.17em; top: 12.6395em">
                  <span class="stl_07 stl_08 stl_35"
                    >第三方个人信息处理规则: &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 13.9395em">
                  <a
                    href="https://dev.mi.com/console/doc/detail?pId=1822"
                    target="_blank"
                    ><span class="stl_07 stl_08 stl_70"
                      >https://dev.mi.com/console/doc/detail?pId=1822
                      &nbsp;</span
                    ></a
                  >
                </div>
                <div class="stl_01" style="left: 9.3299em; top: 16.1095em">
                  <span
                    class="stl_07 stl_08 stl_72"
                    style="word-spacing: 0.2573em"
                    >7.6 第三方公司名称：广东天宸网络科技有限公司 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 11.17em; top: 18.2895em">
                  <span
                    class="stl_07 stl_08 stl_27"
                    style="word-spacing: 0.2631em"
                    >产品类型: Vivo</span
                  ><span
                    class="stl_07 stl_08 stl_45"
                    style="word-spacing: -0.001em"
                    >&nbsp;</span
                  ><span
                    class="stl_07 stl_08 stl_55"
                    style="word-spacing: -0.0072em"
                    >推送 SDK &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 11.17em; top: 20.4595em">
                  <span
                    class="stl_07 stl_08 stl_20"
                    style="word-spacing: 0.252em"
                    >共享信息名称: 存储信息 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 11.17em; top: 22.6395em">
                  <span
                    class="stl_07 stl_08 stl_49"
                    style="word-spacing: 0.264em"
                    >使用目的: 实现将订单等内容内容推送到</span
                  ><span
                    class="stl_07 stl_08 stl_45"
                    style="word-spacing: -0.0067em"
                    >&nbsp;</span
                  ><span
                    class="stl_07 stl_08 stl_73"
                    style="word-spacing: -0.0021em"
                    >vivo 用户 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 11.17em; top: 24.8095em">
                  <span
                    class="stl_07 stl_08 stl_49"
                    style="word-spacing: 0.264em"
                    >使用场景: 用于实现对</span
                  ><span
                    class="stl_07 stl_08 stl_45"
                    style="word-spacing: -0.0069em"
                    >&nbsp;</span
                  ><span
                    class="stl_07 stl_08 stl_74"
                    style="word-spacing: 0.0018em"
                    >Vivo 品牌的手机进行消息推送 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 11.17em; top: 26.9895em">
                  <span
                    class="stl_07 stl_08 stl_23"
                    style="word-spacing: 0.2645em"
                    >共享方式: 内嵌</span
                  ><span
                    class="stl_07 stl_08 stl_45"
                    style="word-spacing: -0.0067em"
                    >&nbsp;</span
                  ><span class="stl_07 stl_08 stl_69">SDK &nbsp;</span>
                </div>
                <div class="stl_01" style="left: 11.17em; top: 29.1595em">
                  <span class="stl_07 stl_08 stl_35"
                    >第三方个人信息处理规则: &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 30.4595em">
                  <a
                    href="https://www.vivo.com.cn/about-vivo/privacy-policy"
                    target="_blank"
                    ><span class="stl_07 stl_08 stl_75"
                      >https://www.vivo.com.cn/about-vivo/privacy-policy
                      &nbsp;</span
                    ></a
                  >
                </div>
                <div class="stl_01" style="left: 9.3299em; top: 32.6395em">
                  <span
                    class="stl_07 stl_08 stl_20"
                    style="word-spacing: 0.258em"
                    >7.7 第三方公司名称：华为软件技术有限公司 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 11.17em; top: 34.8095em">
                  <span
                    class="stl_07 stl_08 stl_14"
                    style="word-spacing: 0.2652em"
                    >产品类型: 华为推送</span
                  ><span
                    class="stl_07 stl_08 stl_45"
                    style="word-spacing: -0.0063em"
                    >&nbsp;</span
                  ><span class="stl_07 stl_08 stl_69">SDK &nbsp;</span>
                </div>
                <div class="stl_01" style="left: 11.17em; top: 36.9895em">
                  <span
                    class="stl_07 stl_08 stl_29"
                    style="word-spacing: 0.2523em"
                    >共享信息名称: 设备信息、网络状态信息、存储信息、位置信息
                    &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 11.17em; top: 39.1595em">
                  <span
                    class="stl_07 stl_08 stl_29"
                    style="word-spacing: 0.2634em"
                    >使用目的: 实现将订单等内容内容推送到华为用户 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 11.17em; top: 41.3395em">
                  <span
                    class="stl_07 stl_08 stl_12"
                    style="word-spacing: 0.2641em"
                    >使用场景: 用于实现对华为品牌的手机进行消息推送 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 11.17em; top: 43.5095em">
                  <span
                    class="stl_07 stl_08 stl_23"
                    style="word-spacing: 0.2645em"
                    >共享方式: 内嵌</span
                  ><span
                    class="stl_07 stl_08 stl_45"
                    style="word-spacing: -0.0067em"
                    >&nbsp;</span
                  ><span class="stl_07 stl_08 stl_69">SDK &nbsp;</span>
                </div>
                <div class="stl_01" style="left: 11.17em; top: 45.6895em">
                  <a
                    href="https://www.huawei.com/cn/privacy-policy"
                    target="_blank"
                    ><span
                      class="stl_07 stl_08 stl_76"
                      style="word-spacing: 0.2487em"
                      >第三方个人信息处理规则:
                      https://www.huawei.com/cn/privacy-policy &nbsp;</span
                    ></a
                  >
                </div>
                <div class="stl_01" style="left: 9.3299em; top: 47.8595em">
                  <span
                    class="stl_07 stl_08 stl_20"
                    style="word-spacing: 0.258em"
                    >7.8 第三方公司名称：广东欢太科技有限公司 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 11.17em; top: 50.0395em">
                  <span
                    class="stl_07 stl_08 stl_49"
                    style="word-spacing: 0.264em"
                    >产品类型: OPPO</span
                  ><span
                    class="stl_07 stl_08 stl_45"
                    style="word-spacing: -0.0097em"
                    >&nbsp;</span
                  ><span
                    class="stl_07 stl_08 stl_55"
                    style="word-spacing: -0.0071em"
                    >推送 SDK &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 11.17em; top: 52.2095em">
                  <span
                    class="stl_07 stl_08 stl_20"
                    style="word-spacing: 0.252em"
                    >共享信息名称: 设备信息 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 11.17em; top: 54.3895em">
                  <span
                    class="stl_07 stl_08 stl_49"
                    style="word-spacing: 0.264em"
                    >使用目的: 实现将订单等内容内容推送到</span
                  ><span
                    class="stl_07 stl_08 stl_45"
                    style="word-spacing: -0.0067em"
                    >&nbsp;</span
                  ><span
                    class="stl_07 stl_08 stl_61"
                    style="word-spacing: -0.0035em"
                    >oppo 用户 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 11.17em; top: 56.5595em">
                  <span
                    class="stl_07 stl_08 stl_49"
                    style="word-spacing: 0.264em"
                    >使用场景: 用于实现对</span
                  ><span
                    class="stl_07 stl_08 stl_45"
                    style="word-spacing: -0.0069em"
                    >&nbsp;</span
                  ><span
                    class="stl_07 stl_08 stl_59"
                    style="word-spacing: -0.0005em"
                    >oppo 品牌的手机进行消息推送 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 11.17em; top: 58.7395em">
                  <span
                    class="stl_07 stl_08 stl_23"
                    style="word-spacing: 0.2645em"
                    >共享方式: 内嵌</span
                  ><span
                    class="stl_07 stl_08 stl_45"
                    style="word-spacing: -0.0067em"
                    >&nbsp;</span
                  ><span class="stl_07 stl_08 stl_69">SDK &nbsp;</span>
                </div>
                <div class="stl_01" style="left: 11.17em; top: 60.9095em">
                  <a href="https://open.oppomobile.com/wiki/doc" target="_blank"
                    ><span
                      class="stl_07 stl_08 stl_71"
                      style="word-spacing: 0.2488em"
                      >第三方个人信息处理规则:
                      https://open.oppomobile.com/wiki/doc &nbsp;</span
                    ></a
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="stl_ stl_02"
            style="
              font-family: 'Times New Roman';
              font-size: medium;
              white-space: normal;
            "
          >
            <div class="stl_view">
              <div class="stl_05 stl_06">
                <div class="stl_01" style="left: 7.5em; top: 6.1095em">
                  <span
                    class="stl_07 stl_08 stl_77"
                    style="font-weight: bold; word-spacing: 0.2571em"
                    >8. 我们需要开启哪些权限： &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 9.3299em; top: 8.2895em">
                  <span
                    class="stl_07 stl_08 stl_32"
                    style="word-spacing: 0.2561em"
                    >8.1 位置权限 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 11.17em; top: 10.4595em">
                  <span class="stl_07 stl_08 stl_26"
                    >提供当前位置商户给您提供的优惠活动 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 9.3299em; top: 12.6395em">
                  <span
                    class="stl_07 stl_08 stl_32"
                    style="word-spacing: 0.2561em"
                    >8.2 存储权限 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 11.17em; top: 14.8095em">
                  <span class="stl_07 stl_08 stl_20"
                    >用于保存海报到本地、以及选择本地图片上传凭证 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 9.3299em; top: 16.9895em">
                  <span
                    class="stl_07 stl_08 stl_59"
                    style="word-spacing: 0.2569em"
                    >8.3 摄像头、麦克风 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 11.17em; top: 19.1595em">
                  <span class="stl_07 stl_08 stl_20"
                    >用于拍摄图片视频等资料，上传凭证&nbsp;&nbsp;</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="stl_ stl_02"
            style="
              font-family: 'Times New Roman';
              font-size: medium;
              white-space: normal;
            "
          >
            <div class="stl_view">
              <div class="stl_05 stl_06">
                <div class="stl_01" style="left: 7.5em; top: 23.5095em">
                  <span class="stl_07 stl_08 stl_18" style="font-weight: bold"
                    >三、我们如何保护和保存你的个人信息 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 25.6895em">
                  <span class="stl_07 stl_08 stl_32" style="font-weight: bold"
                    >（一）我们保护你个人信息的技术与措施 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 27.8595em">
                  <span class="stl_07 stl_08 stl_32" style="font-weight: bold"
                    >我们非常重视个人信息安全，并采取一切合理可行的措施，保护你的个人信息。
                    &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 30.0395em">
                  <span class="stl_07 stl_08 stl_25" style="font-weight: bold"
                    >1.数据安全技术措施 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 32.2095em">
                  <span class="stl_07 stl_08 stl_20">为保障你的信息安全</span
                  ><span class="stl_07 stl_08 stl_11">，</span
                  ><span class="stl_07 stl_08 stl_09">我</span
                  ><span class="stl_07 stl_08 stl_29"
                    >们努力采取各种符合业界标准的安全防护措施</span
                  ><span class="stl_07 stl_08 stl_13">，</span
                  ><span class="stl_07 stl_08 stl_09">包</span
                  ><span class="stl_07 stl_08 stl_15"
                    >括建立合理&nbsp;&nbsp;</span
                  ><span class="stl_07 stl_08 stl_23">的制度规范</span
                  ><span class="stl_07 stl_08 stl_16">、</span
                  ><span class="stl_07 stl_08 stl_09">实</span
                  ><span class="stl_07 stl_08 stl_29"
                    >施合宜的安全技术来防止个人信息遭到未经授权访问</span
                  ><span class="stl_07 stl_08 stl_62">、</span
                  ><span class="stl_07 stl_08 stl_09">公</span
                  ><span class="stl_07 stl_08 stl_78">开披露</span
                  ><span class="stl_07 stl_08 stl_62">、</span
                  ><span class="stl_07 stl_08 stl_09">使</span
                  ><span class="stl_07 stl_08 stl_09">用、</span
                  ><span class="stl_07 stl_08 stl_49">修改、损坏或丢失。</span
                  ><span class="stl_07 stl_08 stl_29"
                    >我们会使用受信赖的保护机制防止数据遭到恶意攻击</span
                  ><span class="stl_07 stl_08 stl_16">，</span
                  ><span class="stl_07 stl_08 stl_09">以</span
                  ><span class="stl_07 stl_08 stl_12"
                    >保障数据在传输过程中的安全。</span
                  ><span class="stl_07 stl_08 stl_24"
                    >使用加密技术对个人信息加密保存，确保数据的保密性。在个人信息使用时，例如订单</span
                  ><span class="stl_07 stl_08 stl_24"
                    >查询、展示时，我们会采用多种脱敏技术增强个人信息在使用中的安全性。我们会采用</span
                  ><span class="stl_07 stl_08 stl_50"
                    >严格的数据访问控制权限控制和多重身份验证机制</span
                  ><span class="stl_07 stl_08 stl_62">，</span
                  ><span class="stl_07 stl_08 stl_09">确</span
                  ><span class="stl_07 stl_08 stl_12"
                    >保只有授权人员才可访问个人信</span
                  >息。同时，在数据使用过程中，我们提供数据安全审计，避免数据被违规使用。&nbsp;&nbsp;
                </div>
                <div class="stl_01" style="left: 7.5em; top: 45.2395em">
                  <span class="stl_07 stl_08 stl_75" style="font-weight: bold"
                    >2.其他安全措施 &nbsp;</span
                  >
                </div>
                <div
                  class="stl_01"
                  style="left: 7.5em; top: 47.4095em; z-index: 491"
                >
                  <span class="stl_07 stl_08 stl_10"
                    >我们建立了数据分类分级制度</span
                  ><span class="stl_07 stl_08 stl_62">、</span
                  ><span class="stl_07 stl_08 stl_09">数</span
                  ><span class="stl_07 stl_08 stl_29"
                    >据安全管理规范来管理及规范个人信息的存储和使用，&nbsp;&nbsp;</span
                  >并通过审计机制对数据安全进行全面检查。&nbsp;
                  我们会采取一切合理可行的措施，确保未收集无关的个人信息。&nbsp;
                  我们定期进行安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。&nbsp;&nbsp;
                </div>
                <div class="stl_01" style="left: 7.5em; top: 55.2395em">
                  <span class="stl_07 stl_08 stl_25" style="font-weight: bold"
                    >（二）你个人信息的保存 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 57.4095em">
                  <span class="stl_07 stl_08 stl_27"
                    >1.存储地点：你的个人信息将存储于中华人民共和国境内。
                    &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 59.5895em">
                  <span class="stl_07 stl_08 stl_52"
                    >2.存储期限：我们只会在达成本声明所述目的所需的期限内保留你的个人信息，除非法&nbsp;&nbsp;</span
                  >律有强制的存留要求。而我们判断前述期限的标准包括：&nbsp;&nbsp;
                </div>
              </div>
            </div>
          </div>
          <div
            class="stl_ stl_02"
            style="
              font-family: 'Times New Roman';
              font-size: medium;
              white-space: normal;
            "
          >
            <div class="stl_view">
              <div class="stl_05 stl_06">
                <div class="stl_01" style="left: 7.5em; top: 6.1095em">
                  <span class="stl_07 stl_08 stl_40">（1</span
                  ><span class="stl_07 stl_08 stl_13">）</span
                  ><span class="stl_07 stl_08 stl_09">完</span
                  ><span class="stl_07 stl_08 stl_10">成与你相关的交易目的</span
                  ><span class="stl_07 stl_08 stl_13">、</span
                  ><span class="stl_07 stl_08 stl_09">维</span
                  ><span class="stl_07 stl_08 stl_10">护相应交易及业务记录</span
                  ><span class="stl_07 stl_08 stl_47">、</span
                  ><span class="stl_07 stl_08 stl_09">应</span
                  ><span class="stl_07 stl_08 stl_63"
                    >对你可能的查询或投诉； &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 8.2895em">
                  <span class="stl_07 stl_08 stl_20"
                    >（2）保证我们为你提供服务的安全和质量； &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 10.4595em">
                  <span class="stl_07 stl_08 stl_72"
                    >（3）你是否同意更长的留存期间； &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 12.6395em">
                  <span class="stl_07 stl_08 stl_72"
                    >（4）是否存在保留期限的其他特别约定。 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 14.8095em">
                  <span class="stl_07 stl_08 stl_17"
                    >在你的个人信息超出保留期间后</span
                  ><span class="stl_07 stl_08 stl_79">，</span
                  ><span class="stl_07 stl_08 stl_09">我</span
                  ><span class="stl_07 stl_08 stl_35"
                    >们会根据适用法律的要求删除你的个人信息</span
                  ><span class="stl_07 stl_08 stl_11">，</span
                  ><span class="stl_07 stl_08 stl_09">或</span
                  ><span class="stl_07 stl_08 stl_09">使&nbsp;&nbsp;</span
                  ><span class="stl_07 stl_08 stl_20"
                    >其匿名化处理。&nbsp;&nbsp;</span
                  ><span class="stl_07 stl_08 stl_29"
                    >请你注意，当你成功注销帐号后，我们将对你的个人信息进行删除或匿名化处理。&nbsp;&nbsp;</span
                  ><span class="stl_07 stl_08 stl_17"
                    >因为适用的法律和安全技术限制</span
                  ><span class="stl_07 stl_08 stl_79">，</span
                  ><span class="stl_07 stl_08 stl_09">我</span
                  ><span class="stl_07 stl_08 stl_12"
                    >们无法立即从备份系统中删除相应的信息</span
                  ><span class="stl_07 stl_08 stl_30">，</span
                  ><span class="stl_07 stl_08 stl_09">我</span
                  ><span class="stl_07 stl_08 stl_31">们将&nbsp;&nbsp;</span
                  ><span class="stl_07 stl_08 stl_50"
                    >安全地存储你的个人信息并限制对其的任何进一步的处理</span
                  ><span class="stl_07 stl_08 stl_62">，</span
                  ><span class="stl_07 stl_08 stl_09">直</span
                  ><span class="stl_07 stl_08 stl_23"
                    >到备份可以删除或实现匿&nbsp;&nbsp;</span
                  >名化。&nbsp;&nbsp;
                </div>
                <div class="stl_01" style="left: 7.5em; top: 25.2395em">
                  <span class="stl_07 stl_08 stl_32" style="font-weight: bold"
                    >（三）您如何管理自己的信息及账号注销 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 27.4095em">
                  <span class="stl_07 stl_08 stl_24"
                    >按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自&nbsp;&nbsp;</span
                  >己的个人信息行使以下权利：&nbsp;&nbsp;
                </div>
                <div class="stl_01" style="left: 7.5em; top: 30.8895em">
                  <span
                    class="stl_07 stl_08 stl_12"
                    style="word-spacing: 0.2515em"
                    >1. 查询权 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 33.0595em">
                  <span class="stl_07 stl_08 stl_50"
                    >您有权查询您的个人信息，法律法规规定的情形例外除外。&nbsp;&nbsp;</span
                  ><span class="stl_07 stl_08 stl_23">您可以通过</span
                  ><span class="stl_80 stl_08 stl_81">“小餐谋</span
                  ><span class="stl_07 stl_08 stl_17">-我的</span
                  ><span class="stl_80 stl_08 stl_09">”</span
                  ><span class="stl_07 stl_08 stl_26"
                    >查询或编辑您的账户中的个人资料信息&nbsp;&nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 37.4095em">
                  <span
                    class="stl_07 stl_08 stl_82"
                    style="word-spacing: 0.2546em"
                    >2. 删除权 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 39.5895em">
                  <span class="stl_07 stl_08 stl_29"
                    >以下情形下，您可以向我们提出删除个人信息的请求：
                    &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 41.7595em">
                  <span
                    class="stl_07 stl_08 stl_10"
                    style="word-spacing: 0.2462em"
                    >（1） 如果我们处理个人信息的行为违反法律法规； &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 43.9395em">
                  <span
                    class="stl_07 stl_08 stl_50"
                    style="word-spacing: 0.2466em"
                    >（2） 如果我们收集、使用您的个人信息，却未征得您的同意；
                    &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 46.1095em">
                  <span
                    class="stl_07 stl_08 stl_50"
                    style="word-spacing: 0.2465em"
                    >（3） 如果我们处理个人信息的行为违反了与您的约定；
                    &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 48.2895em">
                  <span
                    class="stl_07 stl_08 stl_20"
                    style="word-spacing: 0.2469em"
                    >（4） 如果您不再使用我们的产品或服务，或您注销了账号；
                    &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 50.4595em">
                  <span
                    class="stl_07 stl_08 stl_26"
                    style="word-spacing: 0.2464em"
                    >（5） 如果我们不再为您提供产品或服务。 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 52.6395em">
                  <span class="stl_07 stl_08 stl_20"
                    >当您决定删除某项或某几项信息时，您可以联系客服进行删除，拨打客服电话
                    。</span
                  >&nbsp;
                </div>
                <div class="stl_01" style="left: 7.5em; top: 58.2895em">
                  <span
                    class="stl_07 stl_08 stl_12"
                    style="word-spacing: 0.2515em"
                    >4. 注销权 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 60.4576em">
                  <span class="stl_07 stl_08 stl_10"
                    >如果您决定停止使用汉鼎科技的</span
                  ><span class="stl_07 stl_08 stl_11">，</span
                  ><span class="stl_07 stl_08 stl_09">则</span
                  ><span class="stl_07 stl_08 stl_20"
                    >您可以对您的账号进行注销</span
                  ><span class="stl_07 stl_08 stl_11">。</span
                  ><span class="stl_07 stl_08 stl_09">账</span
                  ><span class="stl_07 stl_08 stl_20">号注销可以在</span
                  ><span class="stl_80 stl_08 stl_84">“小餐谋</span
                  ><span class="stl_07 stl_08 stl_59">-我的-设置--注销账号</span
                  ><span class="stl_80 stl_08 stl_81">”</span
                  ><span
                    class="stl_07 stl_08 stl_27"
                    style="word-spacing: 0.0056em"
                    >中实现。需要人工处理的，我们将在 15
                    个工作日内完成审核</span
                  >处理。在注销账户之后，我们将停止为您提供产品或服务，并依据您的要求，删除您&nbsp;&nbsp;<span
                    style="word-spacing: 0.2583em"
                    >的个人信息，法律法规另有规定的除外。
                    注销账号之后此账号内的数据无法恢复。该</span
                  >注销操作不能撤销，请您务必谨慎申请。&nbsp;
                  在以下情形中，按照法律法规的要求，我们将无法响应您的上述请求：&nbsp;&nbsp;
                </div>
              </div>
            </div>
          </div>
          <div
            class="stl_ stl_02"
            style="
              font-family: 'Times New Roman';
              font-size: medium;
              white-space: normal;
            "
          >
            <div class="stl_view">
              <div class="stl_05 stl_06">
                <div class="stl_01" style="left: 7.5em; top: 15.2395em">
                  <span
                    class="stl_07 stl_08 stl_10"
                    style="word-spacing: 0.2463em"
                    >（1） 与个人信息控制者履行法律法规规定的义务相关的；
                    &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 17.4095em">
                  <span
                    class="stl_07 stl_08 stl_26"
                    style="word-spacing: 0.2464em"
                    >（2） 与国家安全、国防安全直接相关的； &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 19.5895em">
                  <span
                    class="stl_07 stl_08 stl_10"
                    style="word-spacing: 0.2463em"
                    >（3） 与公共安全、公共卫生、重大公共利益直接相关的；
                    &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 21.7595em">
                  <span
                    class="stl_07 stl_08 stl_10"
                    style="word-spacing: 0.2463em"
                    >（4） 与刑事侦查、起诉、审判和执行判决等直接相关的；
                    &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 23.9395em">
                  <span
                    class="stl_07 stl_08 stl_20"
                    style="word-spacing: 0.2469em"
                    >（5）
                    个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的；
                    &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 26.1095em">
                  <span
                    class="stl_07 stl_08 stl_26"
                    style="word-spacing: 0.0836em"
                    >（6） 出于维护个人信息主体或其他个人的生命</span
                  ><span class="stl_07 stl_08 stl_43">、</span
                  ><span class="stl_07 stl_08 stl_09">财</span
                  ><span class="stl_07 stl_08 stl_17"
                    >产等重大合法权益但又很难得到本 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 27.4095em">
                  <span class="stl_07 stl_08 stl_20"
                    >人授权同意的； &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 29.5895em">
                  <span
                    class="stl_07 stl_08 stl_26"
                    style="word-spacing: 0.0834em"
                    >（7）
                    响应个人信息主体的请求将导致个人信息主体或其他个人</span
                  ><span class="stl_07 stl_08 stl_43">、</span
                  ><span class="stl_07 stl_08 stl_09">组</span
                  ><span class="stl_07 stl_08 stl_14"
                    >织的合法权益受到 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 30.8895em">
                  <span class="stl_07 stl_08 stl_23">严重损害的； &nbsp;</span>
                </div>
                <div class="stl_01" style="left: 7.5em; top: 33.0595em">
                  <span
                    class="stl_07 stl_08 stl_74"
                    style="word-spacing: 0.2452em"
                    >（8） 涉及商业秘密的。 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 35.2395em">
                  <span class="stl_07 stl_08 stl_18" style="font-weight: bold"
                    >四、未成年人的个人信息保护 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 37.4095em">
                  <span
                    class="stl_07 stl_08 stl_29"
                    style="word-spacing: 0.0057em"
                    >本声明所指的儿童是指不满 14
                    周岁的个人，如果没有父母或监护人的同意，儿童不得&nbsp;&nbsp;</span
                  ><span class="stl_07 stl_08 stl_20">创建自己的用户帐号</span
                  ><span class="stl_07 stl_08 stl_11">。</span
                  ><span class="stl_07 stl_08 stl_09">如</span
                  ><span class="stl_07 stl_08 stl_34">你为未成年人的</span
                  ><span class="stl_07 stl_08 stl_11">，</span
                  ><span class="stl_07 stl_08 stl_09">建</span
                  ><span class="stl_07 stl_08 stl_17"
                    >议你请你的父母或监护人仔细阅读本隐私&nbsp;&nbsp;</span
                  >声明与《儿童个人信息保护规则及监护人须知》，并在征得你的父母或监护人同意的前&nbsp;
                  提下使用我们的服务或向我们提供信息。&nbsp;&nbsp;<span
                    class="stl_07 stl_08 stl_27"
                    >对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况</span
                  ><span class="stl_07 stl_08 stl_16">，</span
                  ><span class="stl_07 stl_08 stl_09">我</span
                  ><span class="stl_07 stl_08 stl_09">们&nbsp;&nbsp;</span
                  >只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、&nbsp;&nbsp;<span
                    class="stl_07 stl_08 stl_15"
                    >共享</span
                  ><span class="stl_07 stl_08 stl_79">、</span
                  ><span class="stl_07 stl_08 stl_09">转</span
                  ><span class="stl_07 stl_08 stl_14">让或披露此信息</span
                  ><span class="stl_07 stl_08 stl_11">。</span
                  ><span class="stl_07 stl_08 stl_09">如</span
                  ><span class="stl_07 stl_08 stl_20"
                    >果我们发现自己在未事先获得可证实的父母同意的情况下&nbsp;&nbsp;</span
                  >收集了儿童的个人信息，则会设法尽快删除相关数据。&nbsp;&nbsp;
                </div>
                <div class="stl_01" style="left: 7.5em; top: 49.5595em">
                  <span class="stl_07 stl_08 stl_34" style="font-weight: bold"
                    >五、如何联系我们 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 51.5872em">
                  <span class="stl_07 stl_08 stl_50"
                    >如有任何问题，可以联系我们客服人员，有问必答。</span
                  ><span style="word-spacing: 0.001em">&nbsp;&nbsp;</span>
                </div>
                <div class="stl_01" style="left: 7.5em; top: 55.2095em">
                  <span class="stl_07 stl_08 stl_27" style="font-weight: bold"
                    >六、通知和修订 &nbsp;</span
                  >
                </div>
                <div class="stl_01" style="left: 7.5em; top: 57.3895em">
                  <span class="stl_07 stl_08 stl_20"
                    >我们的隐私权声明可能变更。&nbsp;&nbsp;</span
                  >未经你明确同意，我们不会限制你按照本隐私权声明所应享有的权利。&nbsp;
                  对于重大变更，我们还会提供更为显著的通知。&nbsp;
                  如你在本声明更新生效后继续使用我们的服务，即代表你已充分阅读、理解并接受更新后的声明并愿意受更新后的声明约束。
                </div>
              </div>
            </div>
          </div>
          <p><br /></p>
          <p><br /></p>
          <p><br /></p>
        </div>
        <p><br /></p>
        <p><br /></p>
      </div>
    </div>
    <p><br /></p>
  </div>
</template>
