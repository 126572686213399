<template>
  <div>
    <div class="w-bar-box">
      <b class="w-title">{{ name }},购物省钱生活平台</b>
      <div class="w-top">
        <!-- <div>
          <div class="w-tip">Iphone 下载</div>
          <img class="w-code" src="../assets/img1.png" alt="" />
        </div>
        <div>
          <div class="w-tip">Android 下载</div>
          <img class="w-code" src="../assets/img2.png" alt="" />
        </div> -->
        <div style="color: #666666; font-size: 18px">
          <div style="color: rgb(255, 192, 1); font-size: 36px">
            {{ name }}APP
          </div>
          <div style="font-size: 20px; color: black; margin: 15px 0 35px 0">
            一款专门线上生活购物省钱的生活工具APP
          </div>
          <div>在美团外卖、饿了么点外卖前，先上{{ name }}搜一下优惠</div>
          <div style="text-align: left">
            或搜索其他正在促销让利的外卖，省钱又省力
          </div>
        </div>
      </div>
      <div class="w-bottom">
        <div v-for="(item, index) in list" :key="index">
          <div class="w-item">
            <div class="w-img">
              <img :src="require('../assets/icon' + index + '.jpg')" alt="" />
              <div class="w-icon">
                <div class="w-num">0{{ index + 1 }}</div>
              </div>
            </div>
            <div class="w-b-title">
              {{ item.title }}
            </div>
            <div style="font-size: 14px">
              {{ item.remark }}
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="w-f">
          <div v-for="(item, index) in footList" :key="index" class="w-f-item">
            <img :src="require('../assets/i' + index + '.png')" alt="" />
            <div>{{ item.title }}</div>
          </div>
        </div>
        <div class="w-e">
          {{ name }} ©2023 海南易品慧网络科技有限公司 琼ICP备2023006155号
          <div class="w-user" @click="onRich">《用户协议》</div>
        </div>
      </div>
    </div>
  </div>
  <el-dialog title="用户协议" v-model="isShow">
    <rich-text></rich-text>
  </el-dialog>
</template>

<script setup>
import { ref } from "vue";
import RichText from "./rich-text.vue";
const isShow = ref(false);
const onRich = () => (isShow.value = true);
const name = "汉鼎科技";
const list = [
  {
    title: "成为会员",
    remark: `用户在${name}可以参加餐饮外卖商家推出的外卖优惠活动`,
  },
  {
    title: "自助下单",
    remark: `${name}会优先值得推荐的大牌优惠，包括肯德基、麦当劳、电影购票等周边优惠低至5折`,
  },
  {
    title: "领优惠券",
    remark: `用户在${name}平台，每天可领美团、饿了么平台外卖优惠券`,
  },
];
const footList = [
  {
    title: "海南省海口龙华区义龙西路侨汇大厦七楼702",
  },
  {
    title: "客服热线：13016274113",
  },
  {
    title: "工作时间：09:00—12:00    13:30-18:30",
  },
];
</script>

<style lang="scss">
.el-dialog__body {
  height: 500px !important;
  overflow: auto !important;
}
.w-bar-box {
  display: flex;
  align-items: center;
  flex-direction: column;
  .w-tip {
    padding: 5px 10px;
    background: rgba(241, 58, 58, 1);
    border-radius: 4px;
    color: white;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .w-title {
    font-size: 30px;
    color: rgb(241, 58, 58);
  }
  .w-top {
    margin-top: 40px;
    display: flex;
    text-align: center;
    & > div {
      margin: 0 20px;
    }
  }
  .w-bottom {
    display: flex;
    margin-top: 15px;
    .w-item {
      width: 300px;
      height: 300px;
      margin-right: 25px;
      img {
        width: 100%;
        height: 100%;
        position: absolute;
      }
      .w-img {
        width: 300px;
        height: 200px;
        position: relative;
      }
      .w-num {
        color: white;
        font-size: 30px;
        font-weight: 500;
        text-align: center;
        line-height: 72px;
      }
      .w-icon {
        position: absolute;
        background-image: url("../assets/icon.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 72px;
        height: 72px;
        bottom: -36px;
        left: 0;
        right: 0;
        margin: auto;
      }
      .w-b-title {
        margin: 40px 0 10px 0;
        text-align: center;
        font-size: 20px;
      }
    }
  }
  .footer {
    width: 100%;
    height: 100px;
    background-color: #222222;
    margin-top: 30px;
    padding: 20px;
    .w-f {
      display: flex;
      justify-content: space-evenly;
      padding-bottom: 15px;
      border-bottom: 1px solid #313a43;
    }
    .w-e {
      margin-top: 15px;
      color: rgb(139, 147, 157);
      text-align: center;
    }
    .w-f-item {
      display: flex;
      color: rgb(139, 147, 157);
      width: 33%;
      justify-content: center;
      img {
        margin-right: 8px;
      }
    }
    .w-user {
      cursor: pointer;
    }
  }
}
</style>
